import type {
  CollectionReference,
  DocumentData,
  DocumentReference,
  QueryCompositeFilterConstraint,
  QueryConstraint,
} from 'firebase/firestore';
import { collection, doc, query } from 'firebase/firestore';

import { firestore } from '@/shared/firebase/config/firebase.config';

export const collectionReference = <T = DocumentData>(
  collectionName: string
) => {
  return collection(firestore, collectionName) as CollectionReference<T>;
};

export const documentReference = <T = DocumentData>(
  collectionName: string,
  id: string
) => doc(collection(firestore, collectionName), id) as DocumentReference<T>;

export const queryReference = <T = DocumentData>(
  collectionName: string,
  queryConstraint: QueryConstraint[],
  compositeFilter?: QueryCompositeFilterConstraint
) => {
  if (compositeFilter) {
    return query(
      query(collectionReference<T>(collectionName), compositeFilter),
      ...queryConstraint
    );
  }
  return query(collectionReference<T>(collectionName), ...queryConstraint);
};
