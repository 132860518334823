import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';

export const env = createEnv({
  /*
   * Serverside Environment variables, not available on the client.
   * Will throw if you access these variables on the client.
   */
  server: {
    FIREBASE_API_KEY: z.string().nonempty(),
    FIREBASE_PROJECT_ID: z.string().nonempty(),
    FIREBASE_ADMIN_CLIENT_EMAIL: z.string().nonempty(),
    FIREBASE_ADMIN_PRIVATE_KEY: z.string().nonempty(),
    NODE_ENV: z.string().nonempty(),
    ANALYZE: z.string().nonempty(),
  },
  /*
   * Environment variables available on the client (and server).
   *
   * :bulb: You'll get typeerrors if these are not prefixed with NEXT_PUBLIC_.
   */
  client: {
    NEXT_PUBLIC_FIREBASE_API_KEY: z.string().nonempty(),
    NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN: z.string().nonempty(),
    NEXT_PUBLIC_FIREBASE_DATABASE_URL: z.string().nonempty(),
    NEXT_PUBLIC_FIREBASE_PROJECT_ID: z.string().nonempty(),
    NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID: z.string().nonempty(),
    NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET: z.string().nonempty(),
    NEXT_PUBLIC_FIREBASE_APP_ID: z.string().nonempty(),
    NEXT_PUBLIC_MEASUREMENT_ID: z.string().nonempty(),
    NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY: z.string().nonempty(),
    NEXT_PUBLIC_VAPID_KEY: z.string().nonempty(),
  },
  /*
   * Due to how Next.js bundles environment variables on Edge and Client,
   * we need to manually destructure them to make sure all are included in bundle.
   *
   * :bulb: You'll get typeerrors if not all variables from `server` & `client` are included here.
   */
  runtimeEnv: {
    FIREBASE_API_KEY: process.env.FIREBASE_API_KEY,
    FIREBASE_PROJECT_ID: process.env.FIREBASE_PROJECT_ID,
    FIREBASE_ADMIN_CLIENT_EMAIL: process.env.FIREBASE_ADMIN_CLIENT_EMAIL,
    FIREBASE_ADMIN_PRIVATE_KEY: process.env.FIREBASE_ADMIN_PRIVATE_KEY,
    NEXT_PUBLIC_FIREBASE_API_KEY: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN:
      process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    NEXT_PUBLIC_FIREBASE_DATABASE_URL:
      process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
    NEXT_PUBLIC_FIREBASE_PROJECT_ID:
      process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID:
      process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET:
      process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    NEXT_PUBLIC_FIREBASE_APP_ID: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    NEXT_PUBLIC_MEASUREMENT_ID: process.env.NEXT_PUBLIC_MEASUREMENT_ID,
    NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY:
      process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
    NEXT_PUBLIC_VAPID_KEY: process.env.NEXT_PUBLIC_VAPID_KEY,
    NODE_ENV: process.env.NODE_ENV,
    ANALYZE: process.env.ANALYZE,
  },
});
