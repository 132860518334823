export enum Collection {
  WORKSPACES = 'workspaces',
  WORKSPACE_MEMBERS = 'workspace_members',
  USERS = 'users',
  INVITATIONS = 'invitations',
  COLLECTIONS = 'collections',
  ITEMS = 'items',
  COMMENTS = 'comments',
  REACTIONS = 'reactions',
  VIEWS = 'views',
  PLANS = 'plans',
  NOTIFICATIONS = 'notifications',
  EMAIL_AUTH_PROVIDERS = 'email_auth_providers',
  GENERAL_SETTINGS = 'general_settings',
  ROLES = 'roles',
  PERMISSIONS = 'permissions',
  SUBSCRIPTIONS = 'subscriptions',
  CONTACTS = 'contacts',
  INVOICES = 'invoices',
  PAYMENT_METHODS = 'payment_methods',
}
