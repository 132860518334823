/* eslint-disable no-restricted-syntax */

import type {
  DocumentData,
  FirestoreError,
  QueryCompositeFilterConstraint,
  QueryConstraint,
  SnapshotOptions,
} from 'firebase/firestore';
import {
  query,
  serverTimestamp,
  startAfter,
  writeBatch,
} from 'firebase/firestore';
import { useState } from 'react';
import type { UseInfiniteQueryOptions, UseQueryOptions } from 'react-query';

import { firestore } from '../../config/firebase.config';
import type { Collection } from '../../constants/collection';
import { getDocId } from '../utils/doc-id';
import { documentReference, queryReference } from '../utils/reference';
import type {
  GetSnapshotSource,
  UseFirestoreHookOptions,
  WithIdField,
} from './firestore';
import {
  useFirestoreDocumentData,
  useFirestoreDocumentDeletion,
  useFirestoreDocumentMutation,
  useFirestoreInfiniteQuery,
  useFirestoreQuery,
  useFirestoreQueryData,
  useFirestoreWriteBatch,
} from './firestore';

type GetQuery<T, R> = {
  collectionName: Collection;
  keys: (string | object)[];
  queryConstraint: QueryConstraint[];
  compositeFilter?: QueryCompositeFilterConstraint;
  options?: UseFirestoreHookOptions & SnapshotOptions;
  useQueryOptions?: Omit<
    UseQueryOptions<WithIdField<T>[], FirestoreError, R>,
    'queryFn'
  >;
};

export const useGetQuery = <T = DocumentData, R = WithIdField<T>[]>(
  args: GetQuery<T, R>
) => {
  const queryRef = queryReference<T>(
    args.collectionName,
    args.queryConstraint,
    args.compositeFilter
  );

  const keys = [args.collectionName, ...args.keys];
  const collectionSnapshot = useFirestoreQueryData(
    keys,
    queryRef,
    {
      ...args.options,
      subscribe: args.options?.subscribe ?? true,
      includeMetadataChanges: args.options?.includeMetadataChanges ?? true,
    },
    {
      ...args.useQueryOptions,
      enabled: args.useQueryOptions?.enabled ?? false,
    }
  );

  const { data, ...rest } = collectionSnapshot;

  return {
    state: { ...rest },
    data: data ?? [],
  };
};

type GetByIdQuery<T, R> = {
  collectionName: Collection;
  id?: string | null;
  options?: UseFirestoreHookOptions & SnapshotOptions;
  useQueryOptions?: Omit<
    UseQueryOptions<WithIdField<T> | undefined, FirestoreError, R>,
    'queryFn' | 'enabled'
  >;
  key?: string;
};

export const useGetById = <T = DocumentData, R = WithIdField<T> | undefined>(
  args: GetByIdQuery<T, R>
) => {
  const docRef = documentReference<T>(
    args.collectionName,
    args.id ?? 'undefined'
  );

  const keys: unknown[] = [args.collectionName];
  if (args.key) {
    keys.push(args.key);
  }
  if (args.id) {
    keys.push(args.id);
  }

  const collectionSnapshot = useFirestoreDocumentData(
    keys,
    docRef,
    {
      ...args.options,
      subscribe: args.options?.subscribe ?? true,
      includeMetadataChanges: args.options?.includeMetadataChanges ?? true,
    },
    {
      ...args.useQueryOptions,
      enabled: !!args.id,
    }
  );
  const { data: documentSnapshot, ...rest } = collectionSnapshot;

  return {
    state: {
      ...rest,
    },
    data: documentSnapshot ?? null,
  };
};

export const useCreateMutation = <T extends DocumentData>(
  collectionName: Collection,
  id?: string
) => {
  const [docId, setDocId] = useState(getDocId(collectionName));
  const docRef = documentReference<T>(collectionName, id ?? docId);
  const mutation = useFirestoreDocumentMutation(docRef);
  const create = (data: T) => {
    mutation.mutate({
      ...data,
      id: id ?? docId,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
    if (!id) setDocId(getDocId(collectionName));
  };
  return {
    create,
    state: { ...mutation },
  };
};

export const useUpdateMutation = <T extends DocumentData>(
  collectionName: Collection,
  id?: string | null
) => {
  const path = id || 'undefined';
  const docRef = documentReference<T>(collectionName, path);
  const mutation = useFirestoreDocumentMutation(docRef);
  const update = (data: T) => {
    mutation.mutate({ ...data, updatedAt: serverTimestamp() });
  };
  return {
    update,
    state: { ...mutation },
  };
};

export const useDeleteMutation = <T extends DocumentData>(
  collectionName: Collection,
  id?: string | null
) => {
  const path = id || 'undefined';
  const docRef = documentReference<T>(collectionName, path);

  const mutation = useFirestoreDocumentDeletion(docRef);
  const remove = () => {
    mutation.mutate();
  };
  return { remove, state: { ...mutation } };
};

export const useBatchCreateMutation = <T extends DocumentData>(
  collectionName: Collection,
  data: T[]
) => {
  const batch = writeBatch(firestore);

  for (const val of data) {
    const id = val?.id ?? getDocId(collectionName);
    const docRef = documentReference<T>(collectionName, id);
    batch.set<T>(docRef, { ...val, createdAt: serverTimestamp(), id });
  }

  const mutation = useFirestoreWriteBatch(batch);

  const batchCreate = () => {
    mutation.mutate();
  };

  return {
    batchCreate,
    state: { ...mutation },
  };
};

export const useBatchUpdateMutation = <T extends DocumentData>(
  collectionName: Collection,
  data: T[]
) => {
  const batch = writeBatch(firestore);
  for (const val of data) {
    const docRef = documentReference<T>(collectionName, val.id);
    batch.set<T>(docRef, val);
  }

  const mutation = useFirestoreWriteBatch(batch);

  const batchUpdate = () => {
    mutation.mutate();
  };

  return {
    batchUpdate,
    state: { ...mutation },
  };
};

export const useBatchDeleteMutation = <T extends DocumentData>(
  collectionName: Collection,
  ids: string[]
) => {
  const batch = writeBatch(firestore);

  for (const id of ids) {
    const docRef = documentReference<T>(collectionName, id);
    batch.delete(docRef);
  }

  const mutation = useFirestoreWriteBatch(batch);

  const batchDelete = () => {
    mutation.mutate();
  };

  return {
    batchDelete,
    state: { ...mutation },
  };
};

type GetInfinityQuery = {
  collectionName: Collection;
  keys: (string | object)[];
  queryConstraint: QueryConstraint[];
  compositeFilter?: QueryCompositeFilterConstraint;
  source?: GetSnapshotSource;
  useInfiniteQueryOptions?: Omit<UseInfiniteQueryOptions, 'queryFn'>;
};
export const useGetInfinityQuery = <T = DocumentData>(
  args: GetInfinityQuery
) => {
  const queryRef = queryReference<T>(
    args.collectionName,
    args.queryConstraint,
    args.compositeFilter
  );

  const collectionSnapshot = useFirestoreInfiniteQuery(
    [args.collectionName, ...args.keys],
    queryRef,

    (documentData) => {
      if (!documentData.docs.length) return undefined; // Added this because when clicking fetchNextPage documentData has length of 0, which gives an error on startAfter
      const lastDocument = documentData.docs[documentData.docs.length - 1]; // On first load if i add a console log here we correctly see the last doc which is "Zenaida Odom"
      return query(queryRef, startAfter(lastDocument));
    },
    { source: args.source },
    {
      ...args.useInfiniteQueryOptions,

      select: (data) => {
        const castedPages = data.pages as Array<T[]>;
        const parsedPages = castedPages.map((page) => {
          return page.map((tData) => ({
            ...tData,
          }));
        });
        return {
          pages: parsedPages as Array<T[][]>,
          pageParams: data.pageParams,
        };
      },
    }
  );

  const { data, ...rest } = collectionSnapshot;
  let returnData: T[][] = [];
  if (data)
    returnData = data.pages.map((page) =>
      page.docs.map((doc) => ({
        ...doc.data(),
      }))
    );

  return {
    state: { ...rest },
    noOfPage: data?.pages.length ?? 0,
    pagesWithSize: data?.pages.map((page) => page.size),
    data: returnData,
  };
};

export const useGetCountQuery = <T = DocumentData, R = WithIdField<T>[]>(
  args: GetQuery<T, R>
) => {
  const queryRef = queryReference<T>(
    args.collectionName,
    args.queryConstraint,
    args.compositeFilter
  );

  const collectionSnapshot = useFirestoreQuery(
    [args.collectionName, ...args.keys],
    queryRef,
    {
      ...args.options,
      subscribe: args.options?.subscribe ?? true,
      includeMetadataChanges: args.options?.includeMetadataChanges ?? true,
    },
    {
      enabled: args.useQueryOptions?.enabled ?? false,
    }
  );

  const { data, ...rest } = collectionSnapshot;

  return {
    count: data?.size ?? 0,
    state: { ...rest },
  };
};
