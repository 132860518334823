import type { FirebaseApp } from '@firebase/app';
import { getApp, getApps, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';
import { getStorage } from 'firebase/storage';

import { clientConfig } from './client.config';

export const getFirebaseApp = () => {
  return (
    !getApps().length ? initializeApp(clientConfig) : getApp()
  ) as FirebaseApp;
};

export const useFirebaseAuth = () => {
  const getFirebaseAuth = getAuth(getFirebaseApp());
  return { getFirebaseAuth };
};

export const firestore = getFirestore(getFirebaseApp());
export const storage = getStorage(getFirebaseApp());

export const generateFMCToken = async (
  permission: NotificationPermission
): Promise<string | null> => {
  const messaging = getMessaging(getFirebaseApp());

  if (permission === 'granted') {
    const isFCMSupported = await isSupported();
    if (!isFCMSupported) return null;
    return getToken(messaging, {
      vapidKey: clientConfig.vapidKey,
    });
  }
  return null;
};
